// import request from "../request"
import request from "../http"
import { FinishedPrimaryQueryListReq } from '@/interface/res/demo'

export function FinishedPrimaryQueryListApi (params:FinishedPrimaryQueryListReq){
    return request({
        url: '/embroidery/finishedPrimary/queryList',
        method: 'get',
        params,
        isToken:false
    })
}


